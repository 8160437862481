// Import dependencies
import React, { Component } from 'react'

// Import styles
import './dataindicator.css'


export default class DataIndicator extends Component {
    numberWithCommas(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    
    render() {
        return (
            <div>
                <div className='dataindicator__text-container'>
                    <p className='dataindicator__year-text'>{ this.props.years }</p>
                    <p className='dataindicator__amount-text'>{ this.numberWithCommas(this.props.amount) }</p>
                </div>

                <div className='dataindicator__container'>
                    <div className='dataindicator__filler' style={{ width: this.props.amount/this.props.total*100+'%' }} />
                </div>
            </div>
        );
    }
}
