// Import dependencies
import React, { Component } from 'react'
import { Carousel } from 'react-responsive-carousel'

// Import styles
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import './homecarousel.css'

// Import images
import aquarium from '../../assets/images/home-aquarium.jpg'
import dome from '../../assets/images/home-dome.jpg'
import fighterJet from '../../assets/images/home-fighter-jet.jpg'
import planeWindow from '../../assets/images/home-plane-window.jpg'
import trainStation from '../../assets/images/home-train-station.jpg'


export default class HomeCarousel extends Component {
    render() {
        return (
            <Carousel
                width='100vw'
                autoPlay
                infiniteLoop
                showArrows={false}
                showStatus={false}
                showThumbs={false}
                showIndicators={false}
                stopOnHover={false}
                swipeable={false}
            >
                <div>
                    <img src={ fighterJet } className='homecarousel__image-container' alt='fighter-jet' />
                </div>
                <div>
                    <img src={ aquarium } className='homecarousel__image-container' alt='aquarium' />
                </div>
                <div>
                    <img src={ trainStation } className='homecarousel__image-container' alt='train-station' />
                </div>
                <div>
                    <img src={ planeWindow } className='homecarousel__image-container' alt='plane-window' />
                </div>
                <div>
                    <img src={ dome } className='homecarousel__image-container' alt='plexiglass-dome' />
                </div>
            </Carousel>
        );
    }
}
