// Import dependencies
import React, { Component } from 'react'

// Import components
import { TitleText } from '../styled/UILibrary'

// Import styles
import './statscomponent.css'


export default class StatLink extends Component {
    render() {
        return (
            <div
                className={
                    this.props.currentIndex === this.props.index ?
                        'statscomponent__stat-container statscomponent__stat-container-selected'
                        :
                        'statscomponent__stat-container'
                }
                onClick={() => this.props.changeIndex(this.props.index)}
            >
                <TitleText
                    minSize={16}
                    maxSize={16}
                    white
                    style={{ writingMode: 'vertical-lr', transform: 'rotate(180deg)' }}
                    nolineheight
                    blue={this.props.currentIndex === this.props.index}
                >
                    { this.props.title }
            </TitleText>

                <div
                    className={
                        this.props.currentIndex === this.props.index ?
                            'statscomponent__stat-container-separator statscomponent__stat-container-separator-selected'
                            :
                            'statscomponent__stat-container-separator'
                    }
                />
            </div>
        );
    }
}
