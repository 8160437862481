// Import dependencies
import React from 'react'

// Import components
import { TitleText, ParagraphText, Box } from '../styled/UILibrary'

// Import styles
import './textbox.css'


export default class TextBox extends React.Component {
    constructor(...args) {
        super(...args);
        this.state = {
            imageVisible: false,
            width: 0
        };
        this.showImage = this.showImage.bind(this);
        this.hideImage = this.hideImage.bind(this);
    }

    showImage() {
        this.setState({ imageVisible: true })
    }
    hideImage() {
        this.setState({ imageVisible: false })
    }

    componentDidMount() {
        this.setState({ width: window.innerWidth });
    }

    render() {
        return (
            <Box
                className='textbox__container'
                padding='8%'
                rounded
                onMouseEnter={this.state.width > 767 ? !this.props.noHover ? this.showImage : null : null}
                onMouseLeave={this.state.width > 767 ? !this.props.noHover ? this.hideImage : null : null}
            >
                <div>
                    <TitleText minSize={22} maxSize={30} nolineheight white={this.state.imageVisible} shadow={this.state.imageVisible}>
                        {this.props.title}
                    </TitleText>
                    <ParagraphText minSize={18} maxSize={20} style={{ marginTop: 20 }} white={this.state.imageVisible} shadow={this.state.imageVisible}>
                        {this.props.paragraph}
                    </ParagraphText>

                    <div className='textbox__separator' style={this.state.imageVisible ? { backgroundColor: '#ffffff' } : this.props.green ? { backgroundColor: '#01D275' } : null} />
                </div>


                <img
                    alt='hover'
                    src={this.props.hoverImage}
                    className={
                        this.state.imageVisible ?
                            'textbox__hover-image textbox__hover-image-visible'
                            :
                            'textbox__hover-image'
                    }
                />
            </Box>
        );
    }
}
