// Import dependencies
import React from "react"

// Import components
import Layout from "../components/layouts/layout"
import SEO from "../components/seo/seo"
import HomeHeader from "../components/home/homeHeader"
import { PageContainer, Margins, Gap150 } from "../components/styled/UILibrary"
import TextSection from "../components/general/textSection"
import TextBox from "../components/general/textBox"
import ContactCallToAction from "../components/general/contactAction"
import HomeCarousel from "../components/home/homeCarousel"
import StatsComponent from "../components/home/statsComponent"
import Header from "../components/header/header"

// Bootstrap components
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"

// Import images
import quality from "../assets/images/quality.jpg"
import credibility from "../assets/images/credibility.jpg"
import adaptibility from "../assets/images/adaptibility.jpg"
import regulations from "../assets/images/regulations.jpg"

// Import styles
import "bootstrap/dist/css/bootstrap.css"
import "./styles/home.css"
import "./styles/history.css"
import "./styles/scrollbar.css"

// Load lodash
var _ = require("lodash")

export default class IndexPage extends React.Component {
  constructor(...args) {
    super(...args)
    this.state = {
      showHeader: false,
    }
    this.handleScroll = this.handleScroll.bind(this)
  }

  handleScroll = () => {
    let scrollTop = window.scrollY
    if (scrollTop > window.innerHeight) {
      if (!this.state.showHeader) {
        this.setState({ showHeader: true })
      }
    } else {
      if (this.state.showHeader) {
        this.setState({ showHeader: false })
      }
    }
  }

  componentDidMount() {
    window.addEventListener("scroll", _.throttle(this.handleScroll, 100))

    // Hello for developers
    console.clear()
    console.log(
      "%cDesigned & developed by Dmitry Poliyivets – https://www.linkedin.com/in/dpoliyivets/",
      "background: #0000FF; color: #ffffff; font-size: 12px; padding: 25px; font-family: 'Montserrat', sans-serif; line-height: 22px"
    )
  }
  componentWillUnmount() {
    window.removeEventListener("scroll", _.throttle(this.handleScroll, 100))
  }

  render() {
    return (
      <Layout>
        <SEO title="Home" keywords={[`gatsby`, `application`, `react`]} />

        <div className="history__container">
          {/* <HeaderImage /> */}
          <HomeCarousel />
          <HomeHeader
            header
            title="Egypt’s leading cast acrylic sheet manufacturer"
          />

          <Header toggle toggleValue={this.state.showHeader} />

          {/* Page contents */}
          <PageContainer margin={"0"}>
            <Margins>
              <Gap150 name="who-we-are" />

              <TextSection
                smallTitle="WHO WE ARE"
                title="Since 1973, we’ve been at the forefront of highly specialized custom solutions in cast acrylic sheets."
                paragraph="We offer better value to our customers. Everything that Spiroplastic makes is guaranteed for 10 years. We are Egypt’s #1 producer of clear acrylic sheets, producing 5000 tons of product per year. We take our position seriously. Being at the top means being responsible. Our commitment to conscientious production and recycling puts us at the forefront of the entire acrylic sheet industry."
              />

              <Gap150 name="our-values" />

              <TextSection
                smallTitle="OUR VALUES"
                title="Responsible"
                paragraph="Nothing means more to us than operating a safe workplace that accounts for worker conditions and the environment in all our decisions. While we know these things are important to our customers, we also know that the product is of primary importance. Without a quality product produced scalably, consistently and on-schedule, none of the rest of our mission gets a chance to have an impact."
              />

              <div style={{ width: "100%", height: 20 }} />
              <Container
                fluid
                style={{ margin: 0, padding: 0 }}
                className="home__equal"
              >
                <Row>
                  <Col
                    xl={6}
                    lg={6}
                    md={6}
                    sm={12}
                    xs={12}
                    className="home__column-padding"
                  >
                    <TextBox
                      title="Quality"
                      paragraph="Quality is our first priority. For the most part, we do custom work. In that sense, your specs are our specs. Since most of our production is for customer-specified materials, if we don’t get these orders exactly right, the customer fails too. We have built our reputation on being able to service very specific niche markets."
                      hoverImage={quality}
                    />
                  </Col>
                  <Col
                    xl={6}
                    lg={6}
                    md={6}
                    sm={12}
                    xs={12}
                    className="home__column-padding"
                  >
                    <TextBox
                      title="Credibility"
                      paragraph="By focusing on the specific needs of our clients, Spiroplastic has developed a wide range of capabilities. We offer an extensive range of colours, surface effects, texture thicknesses and special grades. Our sheets are suitable for outdoor use, maintaining color stabilities, protection from UV rays, and overall outstanding weather resistance."
                      hoverImage={credibility}
                    />
                  </Col>
                  <Col
                    xl={6}
                    lg={6}
                    md={6}
                    sm={12}
                    xs={12}
                    className="home__column-padding"
                  >
                    <TextBox
                      title="Adaptability"
                      paragraph="We value our people and our relationships. Ultimately, we want to grow along with our customers. This happens through true partnerships. Our aim is to be able to react to changes our partners need to make, whether that means scalability in production or adapting to changes in specifications."
                      hoverImage={adaptibility}
                    />
                  </Col>
                  <Col
                    xl={6}
                    lg={6}
                    md={6}
                    sm={12}
                    xs={12}
                    className="home__column-padding"
                  >
                    <TextBox
                      title="Regulations"
                      paragraph="We are an ISO 9001, 14001, 18001 compliant company. Our products meet the standards of regulatory and industry bodies around the world for use in all common applications such as construction, transportation, and optical coatings."
                      hoverImage={regulations}
                    />
                  </Col>
                </Row>
              </Container>

              {/* <div className="home__stats-container">
                <Gap150 name="statistics" />

                <TextSection
                  smallTitle="STATISTICS"
                  title="Over 40 years of successful operation"
                  paragraph="Relevant to 2019, March, 11th."
                />
                <StatsComponent />
              </div> */}

              <Gap150 />

              <ContactCallToAction />
            </Margins>
          </PageContainer>
        </div>
      </Layout>
    )
  }
}
