// Import dependencies
import React, { Component } from 'react'

// Import components
import DataIndicator from './dataIndicator'
import { TitleText } from '../styled/UILibrary'

// Import styles
import './statscomponent.css'
import StatLink from './statLink';

// Define data for stats
const statData = [
    // Statistics 1 data
    [
        {
            years: '2017 – 2018',
            amount: 5000,
            total: 5000
        },
        {
            years: '2016 – 2017',
            amount: 4800,
            total: 5000
        },
        {
            years: '2015 – 2016',
            amount: 3500,
            total: 5000
        },
        {
            left: 'We produced more than',
            bold: '200000',
            right: 'tonnes'
        }
    ],
    // Statistics 2 data
    [
        {
            years: '2017 – 2018',
            amount: 6000,
            total: 6000
        },
        {
            years: '2016 – 2017',
            amount: 3500,
            total: 6000
        },
        {
            years: '2015 – 2016',
            amount: 2000,
            total: 6000
        },
        {
            left: 'We something',
            bold: '3000000',
            right: 'tonnes'
        }
    ],
    // Statistics 3 data
    [
        {
            years: '2017 – 2018',
            amount: 4500,
            total: 4500
        },
        {
            years: '2016 – 2017',
            amount: 3200,
            total: 4500
        },
        {
            years: '2015 – 2016',
            amount: 1230,
            total: 4500
        },
        {
            left: 'We also something',
            bold: '2000',
            right: 'tonnes'
        }
    ]
]


export default class StatsComponent extends Component {
    constructor(...args) {
        super(...args);
        this.state = { currentIndex: 0 };
        this.changeIndex = this.changeIndex.bind(this);
    }

    changeIndex = (index) => {
        this.setState({ currentIndex: index });
    }

    render() {
        return (
            <div className='statscomponent__container'>
                {/* Clickable stat links */}
                <StatLink
                    currentIndex={this.state.currentIndex}
                    title='STATISTICS 1'
                    index={0}
                    changeIndex={this.changeIndex}
                />
                <StatLink
                    currentIndex={this.state.currentIndex}
                    title='STATISTICS 2'
                    index={1}
                    changeIndex={this.changeIndex}
                />
                <StatLink
                    currentIndex={this.state.currentIndex}
                    title='STATISTICS 3'
                    index={2}
                    changeIndex={this.changeIndex}
                />

                <div className='statscomponent__data-container'>
                    <div className='statscomponent__data-title'>
                        <TitleText minSize={18} maxSize={22} nolineheight>
                            {statData[this.state.currentIndex][3].left}
                        </TitleText>
                        <TitleText minSize={30} maxSize={30} nolineheight>
                            &nbsp;{statData[this.state.currentIndex][3].bold}&nbsp;
                        </TitleText>
                        <TitleText minSize={18} maxSize={22} nolineheight>
                            {statData[this.state.currentIndex][3].right}
                        </TitleText>
                    </div>

                    <div className='statscomponent__data-charts-container'>
                        <DataIndicator
                            years={statData[this.state.currentIndex][0].years}
                            amount={statData[this.state.currentIndex][0].amount}
                            total={statData[this.state.currentIndex][0].total}
                        />
                        <DataIndicator
                            years={statData[this.state.currentIndex][1].years}
                            amount={statData[this.state.currentIndex][1].amount}
                            total={statData[this.state.currentIndex][1].total}
                        />
                        <DataIndicator
                            years={statData[this.state.currentIndex][2].years}
                            amount={statData[this.state.currentIndex][2].amount}
                            total={statData[this.state.currentIndex][2].total}
                        />
                    </div>
                </div>
            </div>
        );
    }
}
